import React from "react"
import styled from "styled-components/macro"
import { rem } from "polished"
import { Link } from "gatsby"
import { breakpoint } from "core/layout"
import { color, type } from "core/theme"

const StyledPagination = styled.div`
  background-color: ${color.surface.default};
  display: flex;
  flex-direction: column;
  overflow: hidden;

  a {
    display: flex;
    padding: ${rem(20)};
    text-decoration: none;

    &:nth-child(1) span {
      margin-left: 0;
    }

    &:nth-child(2) {
      border-top: 1px solid ${color.background.default};

      span {
        margin-right: 0;
      }
    }

    > * {
      margin: auto;
    }

    span {
      ${type.bodyText};

      text-overflow: ellipsis;
      white-space: nowrap;
      overflow-x: hidden;
    }

    &:hover span {
      color: ${color.brand.red};
    }
  }

  ${breakpoint.tablet`
    flex-direction: row;
    height: ${rem(80)};

    a {
      &:nth-child(1) span {
        margin-left: auto;
      }

      &:nth-child(2) {
        border-top: none;

        span {
          margin-right: auto;
        }
      }
    }

    > * {
      flex: 1 1 50%;
      max-width: 50%;
    }
  `};
`

export default function PrevNext({ current, works }) {
  const currentIndex = works.findIndex(({ slug }) => slug === current)

  const { prev, next } = React.useMemo(() => {
    let prevIndex = currentIndex - 1
    if (prevIndex < 0) {
      prevIndex = works.length - 1
    }

    let nextIndex = currentIndex + 1
    if (nextIndex > works.length - 1) {
      nextIndex = 0
    }

    const prev = works[prevIndex]
    const next = works[nextIndex]
    return { prev, next }
  }, [currentIndex, works])

  return (
    <StyledPagination>
      <Link to={`/works/${prev.slug}`}>
        <span>Previous “{prev.work.workName}”</span>
      </Link>
      <Link to={`/works/${next.slug}`}>
        <span>Next “{next.work.workName}”</span>
      </Link>
    </StyledPagination>
  )
}
