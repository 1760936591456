import React from "react"
import { Link } from "gatsby"
import styled from "styled-components/macro"
import { rem } from "polished"
import { motion } from "framer-motion"
import { useMatchMedia } from "core/hooks"
import { breakpoint, sizes } from "core/layout"
import { color } from "core/theme"
import { Heading } from "elements"
import { Card } from "modules"

const Grid = styled.div`
  display: flex;
  width: 100%;

  ${breakpoint.site`
    margin: 0 -4rem;
    width: calc(100% + 8rem);
  `};
`

const PlainList = styled.ul`
  list-style: none;
  margin: 0;
  padding-left: 0;
`

const Column = styled(PlainList)`
  flex: 1 1 50%;
  max-width: 50%;
`

const ListItem = styled(motion.li)`
  padding: ${rem(20)};
  position: relative;

  & + & {
    margin-top: ${rem(20)};
  }

  > a {
    display: block;
  }

  ${breakpoint.tablet`
    padding: 0;
  `};
`

const ItemBox = styled.div`
  margin: ${rem(20)} 0;

  ${breakpoint.tablet`
    margin: ${rem(20)};
  `};

  ${breakpoint.laptop`
    margin: ${rem(36)};
  `};
`

const StyledLink = styled(Link)`
  color: ${color.brand.red};
  display: inline-block;
  font-size: ${rem(18)};
  margin: -1em;
  margin-top: 0;
  padding: 1em;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }

  ${breakpoint.tablet`
    margin-top: -1em;
  `};
`

const hoverStyle = {
  zIndex: 1,
}

const itemVariants = {
  hidden: {
    opacity: 0,
  },
  visible: ({ index }) => ({
    opacity: 1,
    transition: {
      type: "spring",
      delay: index * 0.075,
      damping: 100,
      stiffness: 50,
    },
  }),
}

const Item = React.memo(function Item({ data, index, cardProps }) {
  const { id, ...props } = data

  const getDefaultColor = value => {
    const defaultColor = color.text.default
    if (!value || value.length === 0) {
      return defaultColor
    }
    if (!value.startsWith("#")) {
      return `#${value}`
    }
    return value
  }
  const propsWithDefault = {
    ...props,
    color: getDefaultColor(props.color),
  }

  return (
    <ListItem
      data-index={index}
      whileHover={hoverStyle}
      custom={{ index }}
      initial="hidden"
      animate="visible"
      variants={itemVariants}
    >
      <Link to={`/works/${data.slug}`}>
        <Card {...propsWithDefault} {...cardProps} id={id} />
      </Link>
    </ListItem>
  )
})

function mapToItem(card, i, indexMod, cardProps) {
  const cardIndex = indexMod ? indexMod(i) : i
  return (
    <Item key={card.id} data={card} index={cardIndex} cardProps={cardProps} />
  )
}

export default function WorksGrid({ title, cards, action }) {
  const matchTabletLayout = useMatchMedia(sizes.tablet)

  const heading = React.useMemo(
    () => (
      <li key="heading">
        <ItemBox>
          <Heading align={matchTabletLayout ? "right" : "left"}>
            {title}
          </Heading>
        </ItemBox>
      </li>
    ),
    [title, matchTabletLayout]
  )

  //* actionLink = homepage darbu sadaļas poga - see more projects
  const actionLink = action ? (
    <li key="link">
      <ItemBox>
        <StyledLink to={action.to}>{action.label}</StyledLink>
      </ItemBox>
    </li>
  ) : null

  if (matchTabletLayout) {
    const leftCol = cards.filter((_, i) => i % 2 === 0)
    const rightCol = cards.filter((_, i) => i % 2 === 1)

    //* info : novieto actionLink darbu sadaļas kreisajā pusē
    let actionLinkLeft = true
    if (action && cards.length % 2 === 1) {
      actionLinkLeft = false
    }

    return (
      <Grid>
        <Column>
          {leftCol.map((card, i) =>
            mapToItem(card, i, i => i * 2, { isMouseEnabled: true })
          )}
          {actionLinkLeft ? actionLink : null}
        </Column>

        <Column>
          {heading}
          {rightCol.map((card, i) =>
            mapToItem(card, i, i => i * 2 + 1, { isMouseEnabled: true })
          )}
          {!actionLinkLeft ? actionLink : null}
        </Column>
      </Grid>
    )
  }

  return (
    <PlainList>
      {title}
      {cards.map((card, i) => mapToItem(card, i))}
      {actionLink}
    </PlainList>
  )
}
