import React from "react"
import styled from "styled-components/macro"
import { rem, transparentize } from "polished"
import { motion } from "framer-motion"
import { color } from "core/theme"
import { AspectRatio, breakpoint } from "core/layout"

const Wrapper = styled(motion.div)`
  background-color: ${transparentize(0.1, color.surface.default)};
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 101;

  > div {
    flex: 1 1 auto;
    margin: auto;
    padding: ${rem(20)};

    ${breakpoint.laptop`
      padding: ${rem(20)} ${rem(70)};
    `};

    ${breakpoint.site`
      padding: ${rem(20)} ${rem(148)};
    `};
  }
`

const Button = styled.button.attrs({
  type: "button",
})`
  appearance: none;
  background-color: transparent;
  border: none;
  color: ${color.brand.red};
  cursor: pointer;
  font: inherit;
  font-size: ${rem(18)};
  margin: -1em;
  padding: 1em;

  position: absolute;
  top: ${rem(20)};
  right: ${rem(20)};

  &:hover {
    text-decoration: underline;
  }

  &:active {
    outline: none;
  }

  ${breakpoint.tablet`
    top: ${rem(40)};
    right: ${rem(40)};
  `};
`

const Frame = styled.iframe`
  width: 100%;
  height: 100%;
`

const variants = {
  hidden: {
    opacity: 0,
    scale: 1.4,
  },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      type: "spring",
      damping: 50,
    },
  },
}

export default function VideoEmbed({ title, src, onClose }) {
  return (
    <Wrapper initial="hidden" animate="visible" variants={variants}>
      <Button
        onClick={() => {
          onClose && onClose()
        }}
      >
        Close
      </Button>
      <AspectRatio ratio={9 / 16}>
        <Frame
          title={title}
          width="560"
          height="315"
          src={src}
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></Frame>
      </AspectRatio>
    </Wrapper>
  )
}
