import React from "react"
import styled from "styled-components/macro"
import { rem, transparentize } from "polished"
import { motion, /* useSpring, */ useTransform } from "framer-motion"
import { useInView } from "react-intersection-observer"
import { AspectRatio, breakpoint /* , halfWidthImgSizes */ } from "core/layout"
import { color } from "core/theme"
import { useCardMotionEffect } from "core/hooks"
// import { imageWithSrc } from "core/utils"
import Img from "gatsby-image"

const CardWrapper = styled(motion.div)`
  transform: perspective(800px);
  transform-style: preserve-3d;

  ${breakpoint.tablet`
    padding: ${rem(20)};
  `};

  ${breakpoint.laptop`
    padding: ${rem(36)};
  `};
`

const StyledCard = styled(motion.div)`
  position: relative;
  user-select: none;
  transform-style: preserve-3d;
`

const MediaWrapper = styled(motion.div)`
  background-color: ${({ color }) => transparentize(0.6, color)};
  overflow: hidden;

  /* AspectRatio > RatioEnforcer */
  > div > div {
    padding-top: 100%;
  }

  ${breakpoint.mobile`
    > div > div {
      padding-top: ${(3 / 4) * 100}%;
    }
  `};
`

const ImageWrapper = styled(motion.div)`
  .gatsby-image-wrapper {
    height: 100%;
  }

  display: block;
  width: calc(100% + 80px);
  height: calc(100% + 60px);
  margin-top: -30px;
  margin-left: -40px;
  object-fit: cover;
  object-position: ${({ objectPosition }) => objectPosition || "50% 50%"};
`

const ContentWrapper = styled(motion.div)`
  color: ${color.white};
  padding: ${rem(18)} ${rem(20)} ${rem(20)};
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  transform-style: preserve-3d;

  ${breakpoint.laptop`
    padding: 1.25rem 1.5rem 1.5rem;
  `};
`

const Surface = styled(motion.div)`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: ${({ color }) => color};
  z-index: -1;
`

const Title = styled.h2`
  font-size: ${rem(22)};
  margin: 0;
  line-height: 1.1;

  ${breakpoint.laptop`
    font-size: ${rem(27)};
    line-height: 1.25;
  `};
`

const SecondaryText = styled.p`
  font-size: ${rem(15)};
  margin: 0;

  ${breakpoint.laptop`
    font-size: ${rem(18)};
  `};
`

export default function Card({
  id,
  title,
  description,
  showcaseImage,
  showcaseImageMobile,
  pointOfInterest,
  color,
  isMouseEnabled = false,
}) {
  // FIXME: Vai ir iespēja novērst re-render inView itekmē?
  const [wrapperRef, inView, entry] = useInView({
    triggerOnce: false,
    threshold: 1,
  })

  const el = entry && entry.target
  const [
    rotateX,
    rotateY,
    translateX,
    translateY,
    translateZ,
  ] = useCardMotionEffect(el, isMouseEnabled, inView)

  const zOutput = [0, 60]
  const boxShadow = useTransform(translateZ, zOutput, [
    "rgba(0, 0, 0, 0.4) 0 0px 0px 0",
    "rgba(0, 0, 0, 0.2) 0 30px 30px 0",
  ])
  const mediaBrightness = useTransform(translateZ, zOutput, [
    "brightness(1)",
    "brightness(1.1)",
  ])
  const surfaceOpacity = useTransform(translateZ, zOutput, [0.72, 0.95])
  const contentDepth = useTransform(translateZ, zOutput, [1, 40])
  const textShadow = useTransform(translateZ, zOutput, [
    `${color} 0px 0px 0px`,
    `${color} 2px 2px 6px`,
  ])

  // FIXME: Refaktēt par <figure> un <figcaption>?

  // const coverWithSrc = imageWithSrc(id, cover)

  const imageSize = isMouseEnabled
    ? showcaseImage
    : showcaseImageMobile
    ? showcaseImageMobile
    : showcaseImage
  const imageFile = imageSize && imageSize.imageFile

  // aizvieto customAfterContent
  // ieviests <Heading> vajadzībām
  const [, customAfterContent] = /{(.+)}$/.exec(title) || []
  title = customAfterContent
    ? title.replace(/{(.+)}$/, customAfterContent)
    : title

  return (
    <CardWrapper ref={wrapperRef} key={showcaseImage.url}>
      <StyledCard
        style={{
          boxShadow: isMouseEnabled ? boxShadow : undefined,
          translateZ,
          rotateX,
          rotateY,
        }}
      >
        <MediaWrapper
          color={color}
          style={isMouseEnabled ? { filter: mediaBrightness } : {}}
        >
          <AspectRatio ratio={3 / 4}>
            {imageFile && (
              <ImageWrapper
                objectPosition={pointOfInterest}
                alt=""
                style={{ translateX, translateY }}
              >
                <Img fluid={imageFile.childImageSharp.fluid} />
              </ImageWrapper>
            )}
          </AspectRatio>
        </MediaWrapper>

        <ContentWrapper style={{ translateZ: contentDepth }}>
          <Surface color={color} style={{ opacity: surfaceOpacity }} />
          <motion.div
            style={{
              translateZ: contentDepth,
              textShadow: isMouseEnabled ? textShadow : undefined,
            }}
          >
            <Title>{title}</Title>
            <SecondaryText>{description}</SecondaryText>
          </motion.div>
        </ContentWrapper>
      </StyledCard>
    </CardWrapper>
  )
}
