import React from "react"
import styled from "styled-components/macro"
import { breakpoint } from "core/layout"
import { SmartVideo } from "elements"

const StyledIntro = styled.div`
  background-color: #bebcbe;
  display: flex;
  height: 100vw;
  position: relative;
  overflow: hidden;

  > div {
    width: 100%;
  }

  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  ${breakpoint.tablet`
    height: calc(100vh - 120px);
  `};
`

export default function MainIntro(video) {
  return (
    <StyledIntro>
      {video.video && (
        <SmartVideo video={video.video} disablePause={true} />
      )}
    </StyledIntro>
  )
}
